/** @jsx jsx */
import {jsx, css} from "@emotion/core"
import React, {useState, useEffect} from "react"
import {navigate} from "gatsby"
import qs from "qs"
import {window} from "browser-monads"
import algoliasearch from "algoliasearch/lite"
import {
  InstantSearch,
  SearchBox,
  Hits,
  Snippet,
  connectStateResults,
} from "react-instantsearch-dom"

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import Layout from "../components/layout"
import PageWidth from "../components/page-width"
import {SearchBarStyles} from "../components/search-bar"

import {PageHit} from "../../types"
import {ButtonStyles} from "../styles/button-styles"
import {Theme} from "../styles/theme"

const searchClient = algoliasearch(
  "IS77AHXA7X",
  "68e6ca9539314eeaf4254f3a399b3637",
)

const Search = (): JSX.Element => {
  const [searchState, setSearchState] = useState({query: ""})

  useEffect(() => {
    const parameter =
      (qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      }).q as string) || ""

    if (parameter.length > 0) {
      setSearchState({query: parameter})
    }
  }, [])

  useEffect(() => {
    if (searchState.query.length > 0) {
      navigate(`/search?q=${searchState.query}`)
    }
  }, [searchState])

  const Results = connectStateResults(
    ({searchState, searchResults, children, searching}) => {
      if (searchState && !searchState.query) return null
      return searchResults && searchResults.nbHits !== 0 ? (
        children
      ) : (
        <div>
          {!searching &&
            `Er zijn geen resultaten gevonden voor “${searchState.query}”.`}
        </div>
      )
    },
  )

  const Hit = ({hit}: {hit: PageHit}) => {
    // Monkey patch to remove HTML characters from snippet
    hit.content = hit.content.replace(/&[a-z]+;/g, "")
    hit._highlightResult.content.value = hit._highlightResult.content.value.replace(
      /&[a-z]+;/g,
      "",
    )
    hit._snippetResult.content.value = hit._snippetResult.content.value.replace(
      /&[a-z]+;/g,
      "",
    )
    return (
      <div>
        <a href={hit.slug}>{hit.title}</a>
        <Snippet hit={hit} attribute="content" tagName="strong" />
      </div>
    )
  }

  return (
    <Layout title="Site Search">
      <div css={SearchPageStyles}>
        <header>
          <PageWidth text>
            <h3>Zoeken</h3>
          </PageWidth>
        </header>
        <PageWidth text>
          <InstantSearch
            indexName="relevant"
            searchClient={searchClient}
            searchState={searchState}
            onSearchStateChange={setSearchState}
          >
            <div css={AlgoliaOverrides}>
              <div css={[SearchBarStyles, CustomSearchBarStyles]}>
                <FontAwesomeIcon icon={["far", "search"]} />
                <SearchBox
                  defaultRefinement={searchState.query}
                  showLoadingIndicator
                  searchAsYouType={false}
                  autoFocus
                  translations={{
                    placeholder: "Zoeken",
                  }}
                  submit={
                    <div css={ButtonStyles({size: "small"})}>
                      <span className="mobile">
                        <FontAwesomeIcon icon={["far", "search"]} />
                      </span>
                      <span className="desktop">Zoeken</span>
                    </div>
                  }
                />
              </div>
              <Results>
                <Hits hitComponent={Hit} />
              </Results>
            </div>
          </InstantSearch>
        </PageWidth>
      </div>
    </Layout>
  )
}

export default Search

const SearchPageStyles = css`
  header {
    padding: 5rem 0 13rem;
    height: 10rem;
    background: ${Theme.colors.identity.primary};
    color: white;

    .page-width {
      margin: 0 auto;
    }
  }
`

const CustomSearchBarStyles = css`
  padding: 0;

  svg {
    left: 3rem;
  }

  button {
    right: 2rem;
  }
`

const AlgoliaOverrides = css`
  margin-top: -11.5rem;

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  .ais-SearchBox-form {
    button {
      border: none;
      background-color: unset;
      outline: none;
      cursor: pointer;
      color: white;
      &:hover {
        text-decoration: underline;
      }

      > div {
        @media ${Theme.breakpoints.mediumDown} {
          padding: 1.2rem 0.5rem 1.2rem 1.5rem;
        }
        line-height: 2rem;
      }
    }

    button[type="reset"] {
      display: none;
    }
  }

  .ais-Hits-list {
    list-style-type: none;
    padding-left: 0;

    .ais-Hits-item {
      margin-bottom: 2rem;

      a {
        display: block;
        margin-bottom: 0.5rem;
      }

      span {
        font-size: 1.7rem;
      }
    }
  }

  strong {
    font-weight: 400;
  }
`
